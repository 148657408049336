<template>
    <div>
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline logs-form">
            <el-form-item label="阅读类型">
                <el-input v-model="form.readType" placeholder="请输入阅读类型"></el-input>
            </el-form-item>
            <el-form-item label="文档信息">
                <el-input v-model="form.documentName" placeholder="请输入文档信息"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <div class="table-box">
            <el-table
                :data="tableData"
                border>
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="150">
                </el-table-column>
                <el-table-column
                label="阅读日期"
                align="center"
                width="200">
                    <template slot-scope="scope">
                        {{scope.row.readDate | date}}
                    </template>
                </el-table-column>
                <el-table-column
                prop="readerName"
                label="操作人"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                prop="readType"
                label="阅读类型"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                prop="documentName"
                label="文档信息"
                align="center"
                width="300">
                </el-table-column>
                <el-table-column
                label="操作"
                align="center">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-pagination
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-size="form.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <readonly-page ref="readonlypage"></readonly-page>

    </div>
</template>
<script>
import ReadonlyPage from './ReadonlyPage.vue'

  export default {
    data() {
        return {
            total: 0,
            form: {
                // readType: '',
                // documentName: '',
                pageNum: 1,
                pageSize: 10
            },
            tableData: []

        }
    },
    components: {
        ReadonlyPage
    },
    created(){
        this.onLoadList();
    },
    methods: {
        onLoadList(){
            // logsmanagerAPI.getReadLog(this.form).then(res=>{
            //     if(res.code == '0') {
            //         this.total = res.total;
            //         this.tableData = res.data;
            //     } else {
            //         this.$message.error(re.message);
            //     }
            // }).catch(error=>{
            //     this.$message.error(error.message);
            // })
        },
        onSubmit() {

        },
        handleClick(row) {
            this.$refs.readonlypage.onlyFlag = true;
            this.$nextTick(()=>{
                this.$refs.readonlypage.creatLoadInit(false, row);
            })
        },
        handleCurrentChange(val){

            this.form.pageNum = val;
            this.onLoadList();
        }
    }
  }
</script>
<style lang="less" scoped>
.logs-form {
    padding: 20px;
}
.table-box {
    margin: 0 20px 0px 20px;
    padding-bottom: 20px;
}
.pagebox {
    padding: 0px 20px 20px 20px;
}
</style>