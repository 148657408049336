<template>
    <el-dialog
    title="查看"
    :close-on-click-modal="false" 
    :visible.sync="onlyFlag">
    <el-form ref="form" v-if="flag">
        <el-form-item label="时间：">
            <span>{{rowInfos.createTime | date}}</span>
        </el-form-item>
        <el-form-item label="操作人：">
            <span>{{rowInfos.createBy}}</span>
        </el-form-item>
        <el-form-item label="类型：">
            <span>{{rowInfos.type}}</span>
        </el-form-item>
        <el-form-item label="服务接口">
            <span>{{rowInfos.requestUri}}</span>
        </el-form-item>
        <el-form-item label="来访地址">
            <span>{{rowInfos.remoteAddr}}</span>
        </el-form-item>
        <el-form-item label="信息：">
            <span>{{rowInfos.title}}</span>
        </el-form-item>
    </el-form>
    <el-form ref="form" v-if="!flag">
        <el-form-item label="阅读日期：">
            <span>{{rowInfos.readDate | date}}</span>
        </el-form-item>
        <el-form-item label="操作人：">
            <span>{{rowInfos.readerName}}</span>
        </el-form-item>
        <el-form-item label="阅读类型：">
            <span>{{rowInfos.readType}}</span>
        </el-form-item>
        <el-form-item label="文档信息：">
            <span>{{rowInfos.documentName}}</span>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="onlyFlag = false">取 消</el-button>
        <el-button type="primary" @click="onlyFlag = false">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
  export default {
    data() {
      return {
        onlyFlag: false,
        rowInfos: {},
        flag: true
      };
    },
    methods: {
        creatLoadInit(flag, row){
            this.flag = flag;
            this.rowInfos = row;
        }
    }
  };
</script>
<style lang="less" scoped>
.el-form {
    padding: 0 25px;
}
</style>